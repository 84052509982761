<script setup lang="ts">
defineProps<{
  error?: string | undefined;
  limit?: number | undefined;
}>();

const value = defineModel('value', {
  type: String,
  default: '',
});
</script>

<template>
  <label
    class="block flex-1 text-left text-sm font-medium leading-3 text-gray-iron-700"
    :class="$attrs.class"
  >
    <slot></slot>

    <PrimeTextarea
      v-model="value"
      autoResize
      rows="5"
      cols="30"
      size="small"
      class="mt-1.5 block w-full"
      v-bind="$attrs"
    />

    <small class="mt-1 flex justify-between text-left text-xs">
      <span class="text-red-600">
        {{ error }}
      </span>
      <span v-if="limit"> {{ value?.length || 0 }} / {{ limit }} znaków </span>
    </small>
  </label>
</template>
